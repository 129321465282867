/* eslint-disable @next/next/no-img-element */
import { poppins } from "@/app/fonts";
import { Book } from "@/graphql/generated/types";
import { cn } from "@/lib/utils";
import useBookCoverStore from "@/store/useBookCoverStore";
import { IoBook } from "react-icons/io5";
import { useShallow } from "zustand/shallow";
type BookCoverFallbackProps = {
  book?: {
    id?: Book["id"];
    images?: Book["images"];
    title?: Book["title"];
    slug?: Book["slug"];
    authors?: Book["authors"] | string | null;
  };
  fallBackSize?: number;
  hideFallback?: boolean;
  isMobile?: boolean;
  mobileFallbackSize?: number;
  className?: string;
  variant?: "icon" | "card";
};
const BookCoverFallback: React.FC<BookCoverFallbackProps> = ({
  fallBackSize = 80,
  hideFallback,
  isMobile,
  className,
  book,
  mobileFallbackSize = 36
}) => {
  // const localCover = useBookCoverStore((state) => state.localCover)
  // const coverUploadStatus = useBookCoverStore(
  //   (state) => state.coverUploadStatus
  // )

  const {
    localCover
  } = useBookCoverStore(useShallow(state => state.covers[book?.id ?? ""] ?? {
    localCover: null
  }));
  const {
    coverUploadStatus
  } = useBookCoverStore(useShallow(state => state.covers[book?.id ?? ""] ?? {
    coverUploadStatus: "idle"
  }));
  // const localBookId = useBookCoverStore((state) => state.localBookId)
  if (hideFallback) return null;
  if (localCover && coverUploadStatus === "uploading"
  // localBookId === book?.id
  ) return <img src={localCover} alt="Book Cover" className="object-cover w-full h-full" />;

  // ------------------------------------------------------------------------------
  // New mapping after shifting sizes down one notch:
  // • Default (71px design)
  // • @[79px]: now uses old 93px design values
  // • @[93px]: now uses old 118px design values
  // • @[118px]: now uses old 160px design values
  // • @[172px]: now uses old 220px design values
  //
  // For line clamping the title:
  // • Default: 2 lines
  // • @[79px]: still 2 lines
  // • @[93px]: 3 lines
  // • @[118px]: 4 lines
  // • @[172px]: 5 lines
  // The author is always clamped to a single line.
  // ------------------------------------------------------------------------------
  const titleClasses = cn("font-bold break-words cursor-pointer text-white",
  // Line clamping:
  "line-clamp-2",
  // default: clamp 2 lines
  "@[79px]:line-clamp-2", "@[93px]:line-clamp-3", "@[118px]:line-clamp-4", "@[172px]:line-clamp-5",
  // Typography for default (71px):
  "text-[8.5px] leading-[13px] pr-[9px] pt-[8px] pl-[8px]",
  // 79px container width (old 93px design):
  "@[79px]:text-[12px] @[79px]:leading-[18px] @[79px]:pr-[8px] @[79px]:pt-[8px] @[79px]:pl-[10px]",
  // 93px container width (old 118px design):
  "@[93px]:text-[15px] @[93px]:leading-[23px] @[93px]:pr-[11px] @[93px]:pt-[10px] @[93px]:pl-[12px]",
  // 118px container width (old 160px design):
  "@[118px]:text-[20px] @[118px]:leading-[30px] @[118px]:pr-[16px] @[118px]:pt-[16px] @[118px]:pl-[16px]",
  // 172px container width (old 220px design):
  "@[172px]:text-[27px] @[172px]:leading-[41px] @[172px]:pr-[22px] @[172px]:pt-[22px] @[172px]:pl-[22px]");
  const authorClasses = cn("font-medium bg-white/10 px-1 cursor-pointer text-center text-white line-clamp-1",
  // Typography for default (71px):
  "text-[7px] leading-[11px] py-0",
  // 79px container width:
  "@[79px]:text-[9px] @[79px]:leading-[14px]",
  // 93px container width:
  "@[93px]:text-[12px] @[93px]:leading-[18px]",
  // 118px container width:
  "@[118px]:text-[16px] @[118px]:leading-[24px] @[118px]:py-1",
  // 172px container width:
  "@[172px]:text-[22px] @[172px]:leading-[33px] @[172px]:py-1.5");

  // The text container uses justify-between so the title stays at the top
  // and the author is forced to the bottom, regardless of title height.
  const textContainerClasses = cn("flex flex-col w-full cursor-pointer justify-between h-full font-poppins text-white", className);
  return <div className={cn("absolute @container inset-0 flex items-center justify-center bg-gradient-to-b from-[#606a91] to-[#1d1f2b]")} data-sentry-component="BookCoverFallback" data-sentry-source-file="BookCoverFallback.tsx">
      {/* Fallback icon for smaller containers */}
      <div className={cn("absolute @[79px]:hidden inset-0 flex items-center justify-center bg-gradient-to-b from-[#606a91] to-[#1d1f2b]")}>
        <IoBook size={isMobile ? mobileFallbackSize ?? 36 : fallBackSize ?? 80} className="fill-[#e6e6fa]/50" data-sentry-element="IoBook" data-sentry-source-file="BookCoverFallback.tsx" />
      </div>

      {/* Title and Author fallback for larger container widths */}
      <div className={cn("bg-gradient-to-b from-[#606a91] to-[#1d1f2b]", textContainerClasses, poppins.className, "hidden @[79px]:flex")}>
        <p className={titleClasses}>{book?.title}</p>
        <p className={authorClasses}>
          -{" "}
          {book?.authors && Array.isArray(book?.authors) && (book?.authors?.[0]?.name ?? "")}
        </p>
      </div>
    </div>;
};
export default BookCoverFallback;

// export const getTruncatedAuthor = (author: string): string => {
//   // Define max character lengths based on container sizes
//   const maxLength = 9

//   if (author.length <= maxLength) return author

//   const firstName = author.split(" ")[0]

//   return firstName.trim() + "..."

// }