import React from "react";
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTrigger } from "@/components/ui/dialog";
import { useDropzone } from "react-dropzone";
import { Loader2 } from "lucide-react";
import { useState, useCallback, useRef } from "react";
import { Button } from "../ui/button";
import { useBookCover } from "@/hooks/useBookCover";
import useBookCoverStore from "@/store/useBookCoverStore";
import { useShallow } from "zustand/shallow";
interface CoverUploadDialogProps {
  trigger: React.ReactNode;
  initialUrl?: string | null;
  bookId?: string;
  bookSlug?: string | null;
  onUploadComplete?: (uploadId: string) => void;
  shouldUpdateBook?: boolean;
}
const CoverUploadDialog = ({
  trigger,
  initialUrl,
  bookSlug,
  bookId,
  onUploadComplete,
  shouldUpdateBook = true
}: CoverUploadDialogProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [error, setError] = useState<string>("");

  // Use our hook with the initialUrl
  const {
    loading,
    error: hookError,
    uploadCover,
    selectCover
  } = useBookCover({
    initialCoverUrl: initialUrl,
    bookSlug: bookSlug,
    bookId: bookId
  });
  const {
    localCover
  } = useBookCoverStore(useShallow(state => state.covers[bookId ?? ""] ?? {
    localCover: null
  }));

  // Merge errors from the hook and component
  React.useEffect(() => {
    if (hookError) {
      setError(hookError.message);
    }
  }, [hookError]);
  const onDrop = useCallback((acceptedFiles: File[], fileRejections: unknown[]) => {
    setError("");
    if (fileRejections.length > 0) {
      const rejection = fileRejections[0];
      // @ts-expect-error - We know rejection has the right shape
      const msg = rejection.errors[0]?.message;
      setError(msg || "File rejected.");
      return;
    }
    if (acceptedFiles.length === 0) return;

    // The hook will handle validation and preview
    selectCover(acceptedFiles[0]);
  }, [selectCover]);
  const {
    getRootProps,
    getInputProps
  } = useDropzone({
    onDrop,
    accept: {
      "image/*": []
    },
    maxFiles: 1,
    maxSize: 5 * 1024 * 1024
  });
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleTriggerClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleSave = async () => {
    if (!localCover || !bookId) {
      setDialogOpen(false);
      return;
    }
    try {
      setDialogOpen(false);
      const newUploadId = await uploadCover(shouldUpdateBook);
      if (newUploadId && onUploadComplete) {
        onUploadComplete(newUploadId);
      }
      setError("");
    } catch (err) {
      const errMessage = err instanceof Error ? err.message : "Failed to upload cover image";
      setError(errMessage);
    }
  };

  // React.useEffect(() => {
  //   setLocalCover(initialUrl || null)
  // }, [initialUrl, setLocalCover])
  // Display either the local preview, initialUrl, or nothing
  const displayPreview = localCover;
  return <Dialog open={dialogOpen} onOpenChange={setDialogOpen} data-sentry-element="Dialog" data-sentry-component="CoverUploadDialog" data-sentry-source-file="CoverUploadDialog.tsx">
      <DialogTrigger asChild data-sentry-element="DialogTrigger" data-sentry-source-file="CoverUploadDialog.tsx">{trigger}</DialogTrigger>
      <DialogContent hideCloseButton className="max-sm:max-w-[336px] gap-0 sm:max-w-[437px] p-0 border-none rounded-[16px] bg-[#10111e]" data-sentry-element="DialogContent" data-sentry-source-file="CoverUploadDialog.tsx">
        <DialogHeader className="flex border-b border-[#1E1F2B] flex-row pl-6 pr-3 pt-3 pb-3 justify-between space-y-0 items-center gap-2" data-sentry-element="DialogHeader" data-sentry-source-file="CoverUploadDialog.tsx">
          <p className="w-fit tracking-[-0.01em] flex items-center gap-2 max-sm:p-0 text-lg text-white font-bold sm:font-semibold">
            <span>Upload Cover Image</span>
          </p>

          <DialogClose asChild data-sentry-element="DialogClose" data-sentry-source-file="CoverUploadDialog.tsx">
            <Button type="button" variant="link" className="p-3 bg-transparent border border-[#1e1f2b] hover:bg-[#1e1f2b] transition-colors duration-200 rounded-full font-normal text-white flex items-center size-11" data-sentry-element="Button" data-sentry-source-file="CoverUploadDialog.tsx">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="CoverUploadDialog.tsx">
                <path fillRule="evenodd" clipRule="evenodd" d="M16.0237 3.97792C16.3966 4.35081 16.3966 4.9554 16.0237 5.32829L5.32927 16.0227C4.95637 16.3956 4.35179 16.3956 3.97889 16.0227C3.60599 15.6498 3.60599 15.0453 3.97889 14.6723L14.6733 3.97792C15.0463 3.60502 15.6508 3.60502 16.0237 3.97792Z" fill="white" data-sentry-element="path" data-sentry-source-file="CoverUploadDialog.tsx" />
                <path fillRule="evenodd" clipRule="evenodd" d="M3.97889 3.97792C4.35179 3.60502 4.95637 3.60502 5.32927 3.97792L16.0237 14.6723C16.3966 15.0453 16.3966 15.6498 16.0237 16.0227C15.6508 16.3956 15.0463 16.3956 14.6733 16.0227L3.97889 5.32829C3.60599 4.9554 3.60599 4.35081 3.97889 3.97792Z" fill="white" data-sentry-element="path" data-sentry-source-file="CoverUploadDialog.tsx" />
              </svg>
            </Button>
          </DialogClose>
        </DialogHeader>
        <div className="flex flex-col items-center gap-4 p-6">
          <div {...getRootProps()} className="w-[107px] cursor-pointer h-[160px] border-none rounded-[8px] overflow-hidden">
            <input {...getInputProps()} ref={fileInputRef} />
            {displayPreview ?
          // eslint-disable-next-line @next/next/no-img-element
          <img src={displayPreview} alt="Book Cover" className="object-cover w-full h-full" /> : <div className="flex items-center border-none justify-center w-full h-full bg-black/20 rounded-[8px]">
                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M25.28 9.25046C20.7638 7.09421 14.4513 6.03796 6.00001 6.00046C5.20297 5.98963 4.42157 6.22204 3.76001 6.66671C3.21699 7.03374 2.77249 7.52859 2.46559 8.10773C2.1587 8.68686 1.99881 9.33253 2.00001 9.98796V45.7505C2.00001 48.168 3.72001 49.9917 6.00001 49.9917C14.8838 49.9917 23.795 50.8217 29.1325 55.8667C29.2055 55.936 29.2973 55.9824 29.3964 56C29.4956 56.0176 29.5977 56.0057 29.6901 55.9657C29.7825 55.9258 29.8612 55.8596 29.9163 55.7753C29.9714 55.691 30.0005 55.5924 30 55.4917V13.353C30.0001 13.0687 29.9393 12.7877 29.8216 12.5289C29.7038 12.2701 29.5319 12.0396 29.3175 11.853C28.0949 10.8078 26.7368 9.93235 25.28 9.25046ZM60.24 6.66296C59.5781 6.2194 58.7967 5.98828 58 6.00046C49.5488 6.03796 43.2363 7.08921 38.72 9.25046C37.2633 9.9311 35.9048 10.8048 34.6813 11.848C34.4673 12.0349 34.2957 12.2655 34.1782 12.5242C34.0607 12.7829 34 13.0638 34 13.348V55.4892C33.9999 55.586 34.0285 55.6806 34.082 55.7612C34.1355 55.8418 34.2116 55.9048 34.3008 55.9423C34.39 55.9798 34.4882 55.9902 34.5833 55.9721C34.6783 55.9539 34.7659 55.9082 34.835 55.8405C38.0438 52.653 43.675 49.988 58.005 49.9892C59.0659 49.9892 60.0833 49.5678 60.8334 48.8176C61.5836 48.0675 62.005 47.0501 62.005 45.9892V9.98921C62.0065 9.33249 61.8462 8.68551 61.5384 8.1054C61.2306 7.52529 60.7847 7.0299 60.24 6.66296Z" fill="#E6E6FA" fillOpacity="0.9" />
                </svg>
              </div>}
          </div>
          <p className="text-[#e6e6fa]/70 text-sm font-normal text-center max-w-[260px]">
            Make sure image is at least 600x900 pixels and less than 5MB
          </p>
          {error && <p className="text-red-400 mt-2">{error}</p>}

          <DialogFooter className="flex sm:space-x-0 gap-2 max-sm:flex-row w-full justify-end items-center" data-sentry-element="DialogFooter" data-sentry-source-file="CoverUploadDialog.tsx">
            <Button type="button" variant="secondary" onClick={handleTriggerClick} className="px-4 py-3 bg-white/[6%] text-sm font-normal text-white flex items-center gap-2 rounded-[8px] h-[41px]" data-sentry-element="Button" data-sentry-source-file="CoverUploadDialog.tsx">
              Add Cover
            </Button>

            <Button variant="primary" onClick={handleSave} disabled={!localCover || loading} className="px-4 py-3 text-white ml-0 w-fit min-w-[74px] text-sm font-normal flex items-center gap-2 rounded-[8px] h-[41px]" data-sentry-element="Button" data-sentry-source-file="CoverUploadDialog.tsx">
              {loading ? <span className="flex items-center gap-1.5 text-xs">
                  <Loader2 className="animate-spin" size={12} /> Save
                </span> : "Save"}
            </Button>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>;
};
export default CoverUploadDialog;