import { useState, useCallback } from "react"
import { useMutation, ApolloError } from "@apollo/client"
import axios from "axios"
import {
  GenerateUploadUrlDocument,
  EditBookDocument,
  BookBySlugDocument,
} from "@/graphql/generated/types"
import useBookCoverStore from "@/store/useBookCoverStore"
import { useShallow } from "zustand/shallow"

/**
 * Return type for the useBookCover hook
 */
interface UseBookCoverReturn {
  uploadId: string | null
  loading: boolean
  error: ApolloError | Error | undefined
  selectCover: (file: File) => void
  uploadCover: (shouldUpdateBook: boolean) => Promise<string | null>
}

interface UseBookCoverProps {
  /** Initial cover URL to display (from existing book data) */
  initialCoverUrl?: string | null
  bookSlug?: string | null
  bookId?: string | null
}

export function useBookCover(props?: UseBookCoverProps): UseBookCoverReturn {
  const { bookSlug, bookId } = props || {}

  // Local state
  const [loading, setLoading] = useState(false)
  // const localCover = useBookCoverStore((state) => state.localCover)
  const { localCover } = useBookCoverStore(
    useShallow(
      (state) =>
        state.covers[bookId ?? ""] ?? {
          localCover: null,
        }
    )
  )
  const resetCover = useBookCoverStore((state) => state.resetCover)
  // const { coverUploadStatus } = useBookCoverStore(
  //   useShallow(
  //     (state) =>
  //       state.covers[book?.id ?? ""] ?? {
  //         coverUploadStatus: "idle",
  //       }
  //   )
  // )
  const setLocalCover = useBookCoverStore((state) => state.setLocalCover)
  // const setLocalBookId = useBookCoverStore((state) => state.setLocalBookId)
  const setCoverUploadStatus = useBookCoverStore(
    (state) => state.setCoverUploadStatus
  )

  const [uploadId, setUploadId] = useState<string | null>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const [error, setError] = useState<Error | null>(null)

  const [generateUploadUrlMutation, { error: generateError }] = useMutation(
    GenerateUploadUrlDocument
  )
  const [editBookMutation] = useMutation(EditBookDocument)

  // Handle selecting a file for upload
  const selectCover = useCallback(
    (file: File) => {
      // Validate file size
      if (!file) return

      if (file.size > 5 * 1024 * 1024) {
        setError(new Error("File is too large (max 5MB)."))
        return
      }
      // Create an image to check dimensions
      const img = new Image()
      const objectUrl = URL.createObjectURL(file)
      img.src = objectUrl

      img.onload = () => {
        if (img.width < 600 || img.height < 900) {
          setError(new Error("Image dimensions must be at least 600x900."))
          return
        }

        // Set the preview URL and store the file
        if (bookId) {
          setLocalCover(bookId, objectUrl)
        }
        setError(null)
        setSelectedFile(file)
      }

      img.onerror = () => {
        setError(new Error("Failed to load image."))
      }

      // reader.onerror = () => {
      //   setError(new Error("Failed to read file."))
      // }
      // reader.readAsDataURL(file)
    },
    [setLocalCover, bookId]
  )

  // Handle uploading the selected cover
  const uploadCover = useCallback(
    async (shouldUpdateBook: boolean): Promise<string | null> => {
      // If no file is selected but we have an uploadId from a previous upload

      setError(null)

      try {
        setLoading(true)
        if (bookId) {
          setCoverUploadStatus(bookId, "uploading")
        }
        // setLocalBookId(bookId || null)

        // 1. Generate upload URL
        const { data: generateData } = await generateUploadUrlMutation()
        const uploadId = generateData?.generateUploadUrl?.uploadId
        const uploadUrl = generateData?.generateUploadUrl?.uploadUrl

        if (!uploadId || !uploadUrl) {
          throw new Error("Failed to generate upload URL")
        }

        // 2. Upload the file
        await axios.put(uploadUrl, selectedFile, {
          headers: {
            "Content-Type": selectedFile?.type,
          },
        })

        // 3. Update the book if bookId is provided
        if (bookId && shouldUpdateBook) {
          await editBookMutation({
            variables: {
              id: bookId,
              imageId: uploadId,
            },

            awaitRefetchQueries: true,
            refetchQueries: [
              {
                query: BookBySlugDocument,
                variables: {
                  slug: bookSlug,
                },
              },
            ],
            onCompleted: () => {
              if (localCover) {
                URL.revokeObjectURL(localCover)
                setLocalCover(bookId, null)
              }
              resetCover(bookId)
            },
          })
        }

        // 4. Update state
        setUploadId(uploadId)
        if (bookId) {
          setCoverUploadStatus(bookId, "uploaded")
        }

        // if (localCover) URL.revokeObjectURL(localCover)
        // setLocalCover(null)

        return uploadId
      } catch (err) {
        setLoading(false)
        if (bookId) {
          setCoverUploadStatus(bookId, "uploaded")
        }
        if (localCover) URL.revokeObjectURL(localCover)
        if (bookId) {
          setLocalCover(bookId, null)
        }
        // Handle different error types appropriately
        if (err instanceof ApolloError) {
          setError(new Error(err.message))
        } else if (err instanceof Error) {
          setError(err)
        } else {
          setError(new Error("Failed to upload cover image"))
        }
        return null
      }
    },
    [
      // uploadId,
      generateUploadUrlMutation,
      setCoverUploadStatus,
      setLocalCover,
      selectedFile,
      editBookMutation,
      localCover,
      bookSlug,
      bookId,
      resetCover,
      // editBookMutation,
    ]
  )

  // // Reset all cover state
  // const resetCover = useCallback(() => {
  //   if (localCover && localCover.startsWith("blob:")) {
  //     URL.revokeObjectURL(localCover)
  //   }
  //   setLocalCover(initialCoverUrl || null)
  //   setUploadId(null)
  //   setCoverUploadStatus("idle")
  //   setError(null)
  // }, [localCover, setCoverUploadStatus, setLocalCover, initialCoverUrl])

  return {
    uploadId,
    loading: loading,
    error: error || generateError,
    selectCover,
    uploadCover,
  }
}
