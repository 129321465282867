/* eslint-disable @next/next/no-img-element */
"use client";

import { cn } from "@/lib/utils";
import React from "react";
import { IoBook } from "react-icons/io5";
import { Book } from "@/graphql/generated/types";
import Link from "next/link";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useResponsive } from "@/hooks/useResponsive";
import { useInView } from "react-intersection-observer";
import BookCoverFallback from "./BookCoverFallback";
import CoverUploadDialog from "../book/CoverUploadDialog";
import { Button } from "../ui/button";
import useBookCoverStore from "@/store/useBookCoverStore";
import { useShallow } from "zustand/shallow";
type BookCoverProps = {
  book?: {
    id?: Book["id"];
    images?: Book["images"];
    title?: Book["title"];
    slug?: Book["slug"];
    authors?: Book["authors"] | string | null;
  };
  sizes: string;
  hideFallback?: boolean;
  fallBackSize?: number;
  mobileFallbackSize?: number;
  className?: string;
  style?: React.CSSProperties;
  openInDialog?: boolean;
  useDivInsteadOfLink?: boolean;
  allowCoverEdit?: boolean;
  showBorder?: boolean;
  borderColor?: string;
  // variant?: BookCoverVariant
  fallbackClassName?: string;
  borderClassName?: string;
  loading?: "eager" | "lazy";
  showImageLoader?: boolean;
  editTriggerClassName?: string;
  isAdmin?: boolean;
};
const BookCover: React.FC<BookCoverProps> = props => {
  const {
    book,
    hideFallback,
    fallBackSize = 80,
    className,
    mobileFallbackSize = 36,
    style,
    showBorder = false,
    borderClassName,
    borderColor = "#97989d",
    openInDialog = false,
    useDivInsteadOfLink = false,
    allowCoverEdit = false,
    showImageLoader = true,
    // variant = "card",
    sizes,
    fallbackClassName,
    editTriggerClassName,
    isAdmin = false,
    ...rest
  } = props;
  const {
    isMobile
  } = useResponsive();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  // const containerRef = React.useRef<HTMLDivElement>(null)
  // const isVisible = (containerRef, {
  //   threshold: 0.1,
  //   rootMargin: "500px",
  // })

  const {
    ref: containerRef,
    inView
  } = useInView({
    threshold: 0,
    rootMargin: "1024px 0px"
  });
  const {
    localCover
  } = useBookCoverStore(useShallow(state => state.covers[book?.id ?? ""] ?? {
    localCover: null
  }));
  const {
    coverUploadStatus
  } = useBookCoverStore(useShallow(state => state.covers[book?.id ?? ""] ?? {
    coverUploadStatus: "idle"
  }));
  const [isImageLoaded, setIsImageLoaded] = React.useState(false);
  const imgRef = React.useRef<HTMLImageElement>(null);
  React.useEffect(() => {
    // If the image is already cached & complete,
    // then set loaded = true so we skip the skeleton/fade.
    if (imgRef.current && imgRef.current.complete) {
      setIsImageLoaded(true);
    }
  }, []);
  if (!book) {
    return null;
  }

  // const imageUrl = book?.images?.medium

  const Container = useDivInsteadOfLink ? "div" : Link;
  const href = book?.slug ? `/books/${book.slug}` : "";
  const small = book?.images?.small;
  const medium = book?.images?.medium;
  const large = book?.images?.large;
  const xl = book?.images?.xl;
  const hasImage = small && medium && large && xl;
  const imageWrapperClass = cn("relative aspect-[2/3] w-full", className);
  // const fallbackSize = isMobile ? mobileFallbackSize ?? 36 : fallBackSize ?? 80

  if (!hasImage) {
    return <div className="relative">
        <Container href={!useDivInsteadOfLink ? href : ""} onClick={handleClick}>
          <div className={imageWrapperClass} style={style}>
            <BookCoverFallback book={book} className={fallbackClassName} isMobile={isMobile} hideFallback={hideFallback} fallBackSize={fallBackSize} mobileFallbackSize={mobileFallbackSize} />

            {showBorder && <div className={cn("absolute inset-0 pointer-events-none rounded-['inherit']", borderClassName)} style={{
            background: "transparent",
            boxShadow: `inset 0 0 0 0.5px ${isMobile ? "rgba(151, 152, 157, 0.5)" : borderColor}`
          }} />}
          </div>
        </Container>
        {allowCoverEdit && <CoverUploadDialog initialUrl={book?.images?.medium} bookId={book?.id as string} bookSlug={book?.slug as string} trigger={<Button className={cn("absolute bottom-4 hover:bg-white/20 right-4 max-sm:p-3 flex items-center justify-center size-10 sm:size-[58px] bg-white/10 rounded-full", editTriggerClassName)}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="size-6 sm:size-8">
                  <path d="M22.0447 3.5975C22.4595 3.16944 22.9553 2.82818 23.5033 2.59359C24.0513 2.35901 24.6404 2.23578 25.2365 2.2311C25.8325 2.22641 26.4236 2.34035 26.9752 2.56629C27.5267 2.79223 28.0279 3.12565 28.4494 3.54714C28.8708 3.96862 29.2043 4.46975 29.4302 5.02134C29.6561 5.57293 29.7701 6.16396 29.7654 6.76001C29.7607 7.35606 29.6375 7.94522 29.4029 8.49319C29.1683 9.04116 28.8271 9.53698 28.399 9.95179L26.639 11.7118L20.2847 5.3575L22.0447 3.5975ZM19.0733 6.56893L5.14527 20.4969C4.64242 20.9998 4.2767 21.6238 4.0847 22.3095L2.3167 28.6238C2.27585 28.7702 2.27465 28.9248 2.31323 29.0718C2.3518 29.2187 2.42875 29.3528 2.53621 29.4603C2.64366 29.5677 2.77775 29.6447 2.92474 29.6833C3.07173 29.7218 3.22633 29.7206 3.3727 29.6798L9.68585 27.9118C10.372 27.7212 10.997 27.3558 11.4996 26.8512L25.4287 12.9244L19.0733 6.56893Z" fill="white" />
                </svg>
              </Button>} />}
      </div>;
  }

  // let sizesAttr = ""
  let srcSet = "";
  srcSet = hasImage ? `
    ${small} 128w,
    ${medium} 256w,
    ${large} 512w,
    ${xl} 1024w
  ` : "";

  // const respVariant = isMobile ? "thumbnail" : variant

  // switch (respVariant) {
  //   case "thumbnail":
  //     // e.g. 80px container, maybe up to 100px
  //     sizesAttr = sizesProp || "(max-width: 500px) 80px, 120px"
  //     break
  //   case "card":
  //     // e.g. 180px container typical, max maybe 240
  //     sizesAttr = sizesProp || "(max-width: 768px) 160px, 240px"
  //     break
  //   case "large":
  //     // e.g. 340px container on smaller screens, 512 on bigger
  //     sizesAttr = sizesProp || "(max-width: 1024px) 340px, 512px"
  //     break
  //   case "huge":
  //     // e.g. 600px container on smaller, up to 1024 for big screens
  //     sizesAttr = sizesProp || "(max-width: 1440px) 600px, 1024px"
  //     break
  //   default:
  //     sizesAttr = sizesProp || "100vw"
  // }

  function handleClick(e: React.MouseEvent) {
    if (openInDialog) {
      e.preventDefault();
      setDialogOpen(true);
    }
  }
  return <>
      <div className="relative">
        <Container href={!useDivInsteadOfLink ? href : ""} onClick={handleClick} data-sentry-element="Container" data-sentry-source-file="BookCover.tsx">
          <div ref={containerRef} className={imageWrapperClass} style={style}>
            {!isImageLoaded && showImageLoader && <div className={cn("absolute inset-0 flex items-center justify-center bg-gray-800/40", fallbackClassName)}>
                {/* Could be any skeleton, icon, spinner, etc. */}
                <IoBook size={isMobile ? mobileFallbackSize || 36 : fallBackSize || 80} className="animate-pulse fill-slate-300/70" />
              </div>}
            {localCover && isImageLoaded && coverUploadStatus === "uploading" ? <img src={localCover} alt="Book Cover" className="object-cover w-full h-full" /> : hasImage && (inView || isImageLoaded) && <>
                  <img src={small ?? ""} ref={imgRef} srcSet={srcSet} sizes={sizes} loading={"eager"} onLoad={() => setIsImageLoaded(true)} className={cn("object-cover object-center", imageWrapperClass)} style={{
              opacity: isImageLoaded ? 1 : 0,
              transition: "opacity 150ms ease-in-out"
            }}
            // src={imageUrl}
            alt={book?.title || "Book cover"}
            // fill
            {...rest} />
                </>}

            {showBorder && hasImage && isImageLoaded && <div className={cn("absolute inset-0 pointer-events-none rounded-['inherit']", borderClassName)} style={{
            background: "transparent",
            boxShadow: `inset 0 0 0 0.5px ${isMobile ? "rgba(151, 152, 157, 0.5)" : borderColor}`
          }} />}
          </div>
        </Container>
        {isAdmin && allowCoverEdit && <CoverUploadDialog initialUrl={book?.images?.medium} bookId={book?.id as string} bookSlug={book?.slug as string} trigger={<Button className={cn("absolute bottom-4 hover:bg-white/20 right-4 flex items-center justify-center max-sm:p-3 size-10 sm:size-[58px] bg-white/10 rounded-full", editTriggerClassName)}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className="size-6 sm:size-8">
                  <path d="M22.0447 3.5975C22.4595 3.16944 22.9553 2.82818 23.5033 2.59359C24.0513 2.35901 24.6404 2.23578 25.2365 2.2311C25.8325 2.22641 26.4236 2.34035 26.9752 2.56629C27.5267 2.79223 28.0279 3.12565 28.4494 3.54714C28.8708 3.96862 29.2043 4.46975 29.4302 5.02134C29.6561 5.57293 29.7701 6.16396 29.7654 6.76001C29.7607 7.35606 29.6375 7.94522 29.4029 8.49319C29.1683 9.04116 28.8271 9.53698 28.399 9.95179L26.639 11.7118L20.2847 5.3575L22.0447 3.5975ZM19.0733 6.56893L5.14527 20.4969C4.64242 20.9998 4.2767 21.6238 4.0847 22.3095L2.3167 28.6238C2.27585 28.7702 2.27465 28.9248 2.31323 29.0718C2.3518 29.2187 2.42875 29.3528 2.53621 29.4603C2.64366 29.5677 2.77775 29.6447 2.92474 29.6833C3.07173 29.7218 3.22633 29.7206 3.3727 29.6798L9.68585 27.9118C10.372 27.7212 10.997 27.3558 11.4996 26.8512L25.4287 12.9244L19.0733 6.56893Z" fill="white" />
                </svg>
              </Button>} />}

        {openInDialog && <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
            <DialogContent dialogCloseClassName="max-sm:-right-6 max-sm:-top-8 sm:hidden" className="max-sm:max-w-[300px] sm:max-w-fit bg-transparent items-center justify-center border-none p-0 transition-all duration-700">
              <div className="m-0 max-w-fit p-0 bg-transparent">
                {hasImage && <div className="flex max-w-fit items-center justify-center">
                    <img fetchPriority="high" src={large} srcSet={srcSet} sizes={sizes} alt={book?.title || "Book cover"} width={isMobile ? 300 : 400} height={isMobile ? 450 : 600} className={cn("object-cover border-none object-center rounded", isMobile && "w-[300px] h-[450px]")} />
                  </div>}
              </div>
            </DialogContent>
          </Dialog>}
      </div>
    </>;
};
export default BookCover;