// useBookCoverStore.ts
import { create } from "zustand"

type CoverUploadStatus = "idle" | "uploading" | "uploaded"

/**
 * For each book, we track localCover (the blob URL preview)
 * and coverUploadStatus (uploading or done).
 */
interface BookCoverData {
  localCover: string | null
  coverUploadStatus: CoverUploadStatus
}

interface BookCoverStore {
  // A dictionary keyed by bookId:
  covers: Record<string, BookCoverData | undefined>

  // Set or update the local cover for a specific book.
  setLocalCover: (bookId: string, localCover: string | null) => void

  // Update just the upload status for a specific book.
  setCoverUploadStatus: (bookId: string, status: CoverUploadStatus) => void

  // Remove a single book’s entry, or reset everything.
  resetCover: (bookId: string) => void
  resetAllCovers: () => void
}

const useBookCoverStore = create<BookCoverStore>((set) => ({
  covers: {},

  setLocalCover: (bookId, localCover) =>
    set((state) => {
      const prev = state.covers[bookId] ?? {
        localCover: null,
        coverUploadStatus: "idle",
      }
      return {
        covers: {
          ...state.covers,
          [bookId]: {
            ...prev,
            localCover,
          },
        },
      }
    }),

  setCoverUploadStatus: (bookId, status) =>
    set((state) => {
      const prev = state.covers[bookId] ?? {
        localCover: null,
        coverUploadStatus: "idle",
      }
      return {
        covers: {
          ...state.covers,
          [bookId]: {
            ...prev,
            coverUploadStatus: status,
          },
        },
      }
    }),

  resetCover: (bookId) =>
    set((state) => {
      // We remove that entry entirely or reset it to undefined.
      // You could also reset to { localCover: null, coverUploadStatus: "idle" }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [bookId]: _, ...rest } = state.covers
      return { covers: rest }
    }),

  resetAllCovers: () => ({
    covers: {},
  }),
}))

export default useBookCoverStore
